import axios, { AxiosError } from 'axios'
import Env from '../../../../utils/env'
import { disconnectSocket } from './socket'
import { toast } from 'react-toastify'

export const api = axios.create({
  baseURL: `${Env.backend}`
})

api.interceptors.response.use(
  (response) => response,
  (error: AxiosError) => {
    if (error?.response?.status === 401) {
      const requestUrl = error.config.url
      if (requestUrl !== 'login') {
        window.location.href = '/login'
        localStorage.removeItem('@realize: accessToken')
        localStorage.removeItem('@realize: user')
        localStorage.removeItem('@realize: currentPage')
        disconnectSocket()
        toast.error(
          'Sua conexão foi aberta em outro navegador, caso não, reconheça entre em contato com o suporte.'
        )
      }
    }
    return Promise.reject(error)
  }
)

export default api
