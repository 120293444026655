import { Suspense } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { useAuth } from '../hooks/Authentication'
import { IRoute } from './config'
import api from '@api/index'

const RouteWithSubRoutes = (route: IRoute) => {
  const location = useLocation()
  const { user } = useAuth()

  const checkPermissions = async (permissions: string[]) => {
    let userHavePermission = false
    const response = await api.get(`permission`)
    const userPermissions = response.data
    permissions.forEach((routePermission) => {
      if (userPermissions.includes(routePermission)) {
        userHavePermission = true
      }
    })
    if (!userHavePermission) {
      const { permissionRedirectUrl = '/dashboard' } = route
      return <Redirect to={permissionRedirectUrl} />
    }
  }

  // Verifiy in route permissions if the logged user have this permission, if not then redirect to `permissionRedirectUrl`
  if (route.permissions && route.permissions.length > 0) {
    checkPermissions(route.permissions)
  }

  return (
    <Suspense fallback={route.fallback}>
      <Route
        path={route.path}
        render={(props) =>
          route.redirect ? (
            <Redirect to={route.redirect} />
          ) : route.private ? (
            user ? (
              route.component &&
              (route.template ? (
                <route.template>
                  <route.component {...props} routes={route.routes} />
                </route.template>
              ) : (
                <route.component {...props} routes={route.routes} />
              ))
            ) : (
              <Redirect
                to={{ pathname: '/login', state: { from: location } }}
              />
            )
          ) : (
            route.component && (
              <route.component {...props} routes={route.routes} />
            )
          )
        }
      />
    </Suspense>
  )
}

export default RouteWithSubRoutes
